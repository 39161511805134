<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>提现管理</el-breadcrumb-item>
                    <el-breadcrumb-item>提现列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block top">
            <el-row class="total-header">
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">总笔数:</el-col>
                        <el-col :span="span10"><span>{{total.count}}</span></el-col>
                    </el-row>
                </el-col>
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">总提现金额:</el-col>
                        <el-col :span="span10"><span>{{total.cashAmount}}</span></el-col>
                    </el-row>
                </el-col>
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">总提现手续费:</el-col>
                        <el-col :span="span10"><span>{{total.cashFee}}</span></el-col>
                    </el-row>
                </el-col>
                <el-col :span="span4" class="span4">
                    <el-row>
                        <el-col :span="span12">总到账金额:</el-col>
                        <el-col :span="span10"><span>{{total.realAmount}}</span></el-col>
                    </el-row>

                </el-col>
            </el-row>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="时间">
                            <el-date-picker
                                    style="width: 212px"
                                    v-model="date"
                                    @change="orderDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="截止日期"
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="提现状态">
                            <el-select v-model="where.status" placeholder="请选择" clearable>
                                <el-option label="全部" value=""></el-option>
                                <el-option label="成功" value="1"></el-option>
                                <el-option label="出款中" value="2"></el-option>
                                <el-option label="失败" value="3"></el-option>
                                <el-option label="待付款" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="代付状态">
                            <el-select v-model="where.daifuStatus" placeholder="请选择" clearable>
                                <el-option label="全部" value=""></el-option>
                                <el-option label="成功" value="Y"></el-option>
                                <el-option label="失败" value="N"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="出账状态">
                            <el-select v-model="where.creditStatus" placeholder="请选择" clearable>
                                <el-option label="全部" value=""></el-option>
                                <el-option label="已出账" value="Y"></el-option>
                                <el-option label="未出账" value="N"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="收款户名">
                            <el-input
                                    v-model="where.bankAccountName"
                                    placeholder="收款户名支持模糊查询"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()">条件筛选</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button type="primary" icon="el-icon-more" @click="objFltrate.show = !objFltrate.show">
                                字段
                            </el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    :loading="download"
                                    disabled
                                    icon="el-icon-tickets">导出数据</el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                        @selection-change="handleSelectionChange"
                >
                    <div v-for="items in objFltrate.itemsOptions" :key="items.value" :show-overflow-tooltip="true">
                        <el-table-column :prop="items.value" v-if="objFltrate.checkedCities.includes(items.value)"
                                         :label="items.name"></el-table-column>
                    </div>
                    <el-table-column prop="rollbackStatusText" label="回滚入账状态" fixed="right">
                        <template slot-scope="scope">
                            <span :class="'rollbackStatus'+scope.row.rollbackStatus">{{scope.row.rollbackStatusText}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="statusText" label="提现状态" fixed="right">
                        <template slot-scope="scope">
                            <span :class="'status'+scope.row.status">{{scope.row.statusText}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="daifuStatusText" label="代付状态" fixed="right">
                        <template slot-scope="scope">
                            <span :class="'daifuStatus'+scope.row.daifuStatus">{{scope.row.daifuStatusText}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="creditStatusText" label="出账状态" fixed="right">
                        <template slot-scope="scope">
                            <span :class="'creditStatus'+scope.row.creditStatus">{{scope.row.creditStatusText}}</span>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <flteate v-if="objFltrate.show" :objFltrate="objFltrate"></flteate>
    </div>
</template>

<script>
    import {exportFile} from "@/plugins/function.js";
    import flteate from "@/components/admin/common/filtrate.vue";

    export default {
        components: {
            flteate,
        },
        props: {
            state: Number,
        },
        data() {
            return {
                span: 6,
                span4: 5,
                span12: 12,
                span10: 10,
                list: [],
                date: "",
                row: {},
                loading: false,
                dialogDetailVisible: false,
                dialogEditVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                rolesData: [],
                selectId: "",
                where: {
                    pageNum: "",//分页，页数
                    pageSize: "",//分页，每页大小
                    status: "",//提现状态【1-成功  2-出款中  3-失败  4-待付款】
                    daifuStatus: "",//代付状态【Y-成功  N-失败】
                    creditStatus: "",//出账状态【Y-已出账  N-未出账】
                    bankAccountName: "",//收款户名（持卡人姓名）  支持模糊查询
                    startDt: "",//开始日期【yyyy-MM-dd】
                    endDt: "",//结束日期【yyyy-MM-dd】
                },
                total:{
                    count:0,  // 总笔数
                    cashAmount:0, // 总提现金额
                    cashFee:0, // 总提现手续费
                    realAmount:0, // 总到账金额
                },
                download:false,
                objFltrate: {
                    show: false,
                    localStorage: 'fltrate_withdrawal',
                    checkedCities: [
                        "cashAmount",
                        "cashFee",
                        "cashTaxFee",
                        "realAmount",
                        "daifuAmount",
                        "bankAccountName",
                        "bankName",
                        "createTime",
                    ],
                    itemsOptions: [
                        {value:"externalId",name:"提现流水号"},
                        {value:"cashAmount",name:"提现金额"},
                        {value:"cashFee",name:"提现手续费"},
                        {value:"cashTaxFee",name:"提现税费"},
                        {value:"realAmount",name:"到账金额"},
                        {value:"daifuFlowId",name:"代付流水号"},
                        {value:"daifuAmount",name:"代付金额"},
                        {value:"daifuStatusDesc",name:"错误描述"},
                        {value:"daifuTime",name:"代付时间"},
                        {value:"bankAccountNo",name:"收款账号"},
                        {value:"phone",name:"收款手机"},
                        {value:"bankAccountName",name:"收款户名"},
                        {value:"bankOpenbankname",name:"收款开户行"},
                        {value:"bankAccountType",name:"收款账户类型"},
                        {value:"creditFlowId",name:"出账流水号"},
                        {value:"creditStatusDesc",name:"出账状态错误描述"},
                        {value:"creditTime",name:"出账时间"},
                        {value:"accountOwnerRoleText",name:"拥有者角色"},
                        {value:"accountTypeText",name:"账户类型"},
                        {value:"rollbackFlowId",name:"回滚入账流水ID"},
                        {value:"rollbackStatusDesc",name:"回滚入账状态描述"},
                        {value:"bankName",name:"银行名称"},
                        {value:"createTime",name:"创建时间"},
                    ],
                },
            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.profitCashOrderList, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.list = res.data.records;
                    this.loading = false;
                    this.getTotal();
                });
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
            orderDate(obj) {
                if (obj) {
                    this.where.startDt = obj[0];
                    this.where.endDt = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.startDt = '';
                        this.where.endDt = '';
                    }
                });
            },
            exportData() {
                // 提现列表导出Excel
                this.download = true;
                this.$post(this.$api.profitCashOrderListExport, {...this.where, export: true}, true).then((res) => {
                    exportFile(res.data, decodeURI(res.headers.filename));
                    this.download = false;
                });
            },
            post(url, data) {
                let that = this;
                this.$get(url, data).then((res) => {
                    if (res.code == 1) {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                        that.listData();
                    } else {
                        this.$message({
                            type: "error",
                            message: "失败!",
                        });
                    }
                });
            },
            getTotal(){
                this.$post(this.$api.profitCashOrderTotal, this.where).then((res) => {
                    if (res.code === 1) {
                        this.total = res.data;
                    }
                });
            },
            getFltrate() {
                let obj = localStorage.getItem(this.objFltrate.localStorage);
                if (obj) {
                    this.objFltrate.checkedCities = JSON.parse(obj);
                }
            },
        },

        created() {
            this.listData();
            this.getFltrate();
        },
    };
</script>
<style lang="scss" scoped>
    .total-header {
        margin-bottom: 0px !important;
        margin-top: 15px;

        .el-col {
            margin-right: 15px;

            :last-child {
                margin-right: 0px;
            }
        }
    }

    .el-row {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .header-form {
        height: 40px;
        padding: 10px 0 10px 0;
    }

    .span4 {
        background: rgb(217, 236, 255);
        font-size: 16px;
        padding: 30px 0;
        text-align: center;
        vertical-align: center;

        span {
            font-size: 18px;
        }
    }

    /deep/ .el-input-number.is-controls-right .el-input__inner {
        padding-left: 2px;
        padding-right: 32px;
    }

    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }

    .el-select {
        width: 120px;
    }
    .status1,.daifuStatusY,.creditStatusY,.rollbackStatusY{
        color: #67C23A;
    }
    .status2,.daifuStatusU,.creditStatusU,.rollbackStatusU{
        color: #E6A23C;
    }
    .status3,.daifuStatusN,.creditStatusN,.rollbackStatus{
        color: #F56C6C;
    }
    .status4,{
        color: #909399;
    }
    /*.OPERATION_CENTER,.AD_PAY{
        color: #409EFF;
    }
    .OPERATION_CENTER,.AD_PAY{
        color: #a84a26;
    }*/
</style>
