
<template>
    <div class="body">
        <el-dialog
                title="显示字段"
                :visible.sync="dialogSendVisible"
                width="35%"
                :before-close="handleSendClose"
        >
            <el-timeline>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="city in cities" :label="city.value" :key="city.value">{{city.name}}</el-checkbox>
                </el-checkbox-group>
            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleSendClose">取 消</el-button>
                <el-button type="primary" @click="confirm()">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props:{
            objFltrate:{
                type:Object,
                required: false,
            },
        },
        data() {
            return {
                checkAll: false,
                checkedCities: [],
                cities: [],
                isIndeterminate: true,
                dialogSendVisible: true,
            };
        },
        created() {
            this.checkedCities = this.objFltrate.checkedCities;
            this.cities = this.objFltrate.itemsOptions;
        },
        methods: {
            handleCheckAllChange(val) {
                let items = [];
                // eslint-disable-next-line no-unused-vars
                this.objFltrate.itemsOptions.map((res,index)=>{
                    items.push(res.value.toString());
                });
                this.checkedCities = val ? items : [];
                this.isIndeterminate = false;
                this.setLocalStorage();
            },
            handleCheckedCitiesChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.cities.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
                this.setLocalStorage();
            },
            handleSendClose() {
                this.dialogSendVisible = false;
                this.$parent.objFltrate.show = false;
            },
            confirm() {
                this.setLocalStorage();
                this.handleSendClose();
            },
            setLocalStorage(){
                localStorage.setItem(this.objFltrate.localStorage, JSON.stringify(this.checkedCities));
                this.$parent.getFltrate();
            },
        }
    };
</script>

<style scoped>
    .body{

    }
    .el-checkbox{
        width: 170px;
        margin-top: 5px;
    }
</style>
